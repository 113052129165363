<template>
  <div class="tao-bao-application-manager">
    <common-table
      ref="applicationTable"
      :apiKey="tableConfig.apiKey"
      :autoLoad="tableConfig.autoLoad"
      :search-params="searchForm"
      :columns="tableConfig.columns"
      :options="tableConfig.options"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableConfig: {
        apiKey: "fw-taobao-application-config-list",
        autoLoad: true,
        columns: [
          {
            label: "名称",
            prop: "appName",
          },
          {
            label: "appKey",
            prop: "appKey",
          },
          {
            label: "类型",
            prop: "_caption.appType",
          },
          {
            label: "是否默认",
            prop: "_caption.defaultFlag",
          },
          {
            label: "创建时间",
            prop: "createdTime",
          },
        ],
        options: [
          {
            label: "授权",
            powerId: "50-20-10",
            handler: (row) => {
              this.openAutoWindow(row);
            },
          },
          {
            label: "设为默认",
            powerId: "50-20-20",
            handler: (row) => {
              this.setDefault(row);
            },
          },
        ],
      },
      searchForm: {},
    };
  },
  methods: {
    async openAutoWindow({ id }) {
      let loading = this.showLoading();
      try {
        let { val } = await this.$http.doApi('fw-taobao-access-getAuthUrl', {act:'admin', appId: id, redirectUri: location.origin + "/taobao/auth/callback"});
        window.open(val, '_blank');
        await this.$confirm("是否完成授权？", "提示", {
          confirmButtonText: "已授权",
          cancelButtonText: "未授权",
        });
        this.$refs.applicationTable.refresh();
      } catch (e) {
        console.log(e);
        this.$message.error('获取授权地址失败');
      } finally {
        loading.close();
      }
    },
    async setDefault({id}) {
      let loading = this.showLoading();
      try {
        await this.$http.doApi('fw-taobao-application-config-setDefault', {id});
        this.$message.success('设置成功');
      } catch(e) {
        console.log(e);
        this.$message.error('设置失败');
      } finally {
        loading.close();
      }
    },
    showLoading() {
      return this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
  },
};
</script>

<style lang="less">
.tao-bao-application-manager {
}
</style>